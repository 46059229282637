<template>
   <section id="hero" class="d-flex justify-content-center align-items-center">
    <div class="container position-relative" data-aos="zoom-in" data-aos-delay="100">
      <h1 v-html="$t('sira-full-alt')"></h1>
      <h5 class="tag-line"><b><i>{{$t('tag-line')}}</i></b></h5>
      <!-- <h2>We are team of talented designers making websites with Bootstrap</h2> -->
      <!-- <router-link :to="{name : Register, params : {slug : $route.params.slug}}" class="btn-get-started">{{$t("register")}}</router-link> -->
      <!-- <a href="courses.html" class="btn-get-started">{{$t("register")}}</a> -->
      <a :href="`/register`" class="btn-get-started" v-if="!$auth.check()">{{$t("register")}}</a>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.tag-line {
  color:rgb(29, 159, 198);
}
</style>

