<template>
  <div class="home">
    <Hero/>
    <main id="main">

      <section id="popular-courses" class="events">
          <div class="container" data-aos="fade-up">

            <div class="section-title mb-3">
              <p class="text-center">{{$t("call-for-applications")}}</p>
            </div>

            <div class="events" data-aos="zoom-in" data-aos-delay="100">
               <div class="row">
                <div class="card">
                  <div class="card-body">
                    <div class="col-lg-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                      <div class="icon-boxes d-flex flex-column justify-content-center">
                        <div class="row">
                          <p>{{$t("call-intro-p-1")}}</p>
                          <p v-html="$t('call-intro-p-2')"></p>
                        </div>
                        <div class="text-center">
                        <a :href="`/call`" class="more-btn get-started-btn">{{$t("learn-more")}} <i class="bx bx-chevron-right"></i>
                        </a>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               </div>
            </div>

          </div>
        </section>

      <section id="events" class="events">
      <div class="container" data-aos="fade-up">

        <!-- <div class="row">
           <div class="col-md-6 d-flex align-items-stretch">
            <div class="card">
              <div class="card-img">
                <img src="/assets/img/events-1.jpg" alt="...">
              </div>
              <div class="card-body">
                <h5 class="card-title"><a href="">{{$t("about-sira")}}</a></h5>
                <p class="card-text">{{$t("intro-about")}}</p>
                <div class="text-center">
                  <a :href="`/about`" class="more-btn get-started-btn">{{$t("learn-more")}} <i class="bx bx-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-stretch">
            <div class="card">
              <div class="card-img">
                <img src="/assets/img/events-2.jpg" alt="...">
              </div>
              <div class="card-body">
                <h5 class="card-title"><a href="">{{$t("evaluation-criteria")}}</a></h5>
                <p class="card-text">{{$t("evaluation-criteria-intro")}}</p>
                <div class="text-center">
                  <a :href="`/criteria`" class="more-btn get-started-btn">{{$t("learn-more")}} <i class="bx bx-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div> -->

      </div>
    </section>
    </main>
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'

export default {
  components: { Hero },
  name: 'Home',
}
</script>
